<template>
  <div class="home-chart">
    <!-- Tab Menu -->
    <v-layout justify-center class="btn-toggles">
      <div v-if="Object.keys(sales.salesMonthTotal).length === 0" class="pa-3">
        {{
          Object.keys(sales.salesMonthTotal).length === 0 ? "carregando..." : ""
        }}
      </div>
      <v-btn-toggle
        :value="chartBtnToggle"
        @change="(value) => $emit('change', value)"
        mandatory
        class="d-flex overflow-x-auto"
        tile
        borderless
        style="border: solid 1px #1a237e !important; width: 95%"
        background-color="indigo darken-1"
      >
        <!-- Btn Toggles -->
        <v-btn
          active-class="indigo darken-4 white--text"
          class="text-capitalize"
          height="25"
          :min-width="getBtnToggleMinWidth + '%'"
          >Vendas
        </v-btn>
        <v-btn
          active-class="indigo darken-4 white--text"
          class="text-uppercase"
          height="25"
          :min-width="getBtnToggleMinWidth + '%'"
          >TV
        </v-btn>
        <v-btn
          active-class="indigo darken-4 white--text"
          class="text-uppercase"
          height="25"
          :min-width="getBtnToggleMinWidth + '%'"
          >TM
        </v-btn>
        <v-btn
          active-class="indigo darken-4 white--text"
          class="text-uppercase"
          height="25"
          :min-width="getBtnToggleMinWidth + '%'"
          >QTDE
        </v-btn>
      </v-btn-toggle>
    </v-layout>
    <!-- Chart -->
    <v-layout class="mt-5 flex-grow-1 overflow-x-auto">
      <line-chart
        :chart-data="fillData()"
        :options="$data.chartOptions"
        :styles="getChartReactiveStyles"
      />
    </v-layout>
    <v-dialog :value="$store.state.showFullscreenChart" fullscreen>
      <v-card>
        <v-card-title style="position: absolute">
          <div class="body-2">
            {{ $data.titles[chartBtnToggle] }}
          </div>
        </v-card-title>
        <v-card-actions>
          <v-layout
            justify-end
            style="position: absolute; width: 100%; left: -10px; top: 0.5rem"
          >
            <v-btn
              title="fechar"
              large
              icon
              @click="$store.commit('setShowFullscreenChart', false)"
            >
              <v-icon color="grey">mdi-close</v-icon>
            </v-btn>
          </v-layout>
        </v-card-actions>
        <!-- Tab Menu -->
        <v-layout justify-center class="btn-toggles pt-9">
          <v-btn-toggle
            :value="chartBtnToggle"
            @change="(value) => $emit('change', value)"
            mandatory
            class="d-flex overflow-x-auto"
            tile
            borderless
            style="border: solid 1px #1a237e !important; width: 80%"
            background-color="indigo darken-1"
          >
            <!-- Btn Toggles -->
            <v-btn
              active-class="indigo darken-4 white--text"
              class="text-capitalize"
              height="25"
              :min-width="getBtnToggleMinWidth + '%'"
              >Vendas
            </v-btn>
            <v-btn
              active-class="indigo darken-4 white--text"
              class="text-uppercase"
              height="25"
              :min-width="getBtnToggleMinWidth + '%'"
              >TV
            </v-btn>
            <v-btn
              active-class="indigo darken-4 white--text"
              class="text-uppercase"
              height="25"
              :min-width="getBtnToggleMinWidth + '%'"
              >TM
            </v-btn>
            <v-btn
              active-class="indigo darken-4 white--text"
              class="text-uppercase"
              height="25"
              :min-width="getBtnToggleMinWidth + '%'"
              >QTDE
            </v-btn>
          </v-btn-toggle>
        </v-layout>
        <v-layout class="pt-5 flex-grow-1 overflow-x-auto">
          <line-chart
            :chart-data="fillData()"
            :options="$data.chartOptions"
            :styles="getChartReactiveStyles"
          />
        </v-layout>
        <slot name="table" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LineChart from "@/components/charts/line-chart";
export default {
  components: { LineChart },
  props: ["chartBtnToggle"],
  model: {
    prop: "chartBtnToggle",
    event: "change",
  },
  data: function () {
    return {
      titles: ["VENDAS", "TOTAL DE VENDAS", "TIKET MÉDIO", "QUANTIDADE"],
      showFullscreenChart: true,
      chartOptions: {
        elements: {
          line: {
            tension: 0,
          },
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              ticks: {
                callback: (value) => {
                  switch (Number(this.chartBtnToggle)) {
                    case 0:
                    case 2:
                      return value.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      });
                    case 1:
                    case 3:
                      return value;
                  }
                },
              },
            },
          ],
          y: {
            stacked: true,
            grid: {
              display: true,
              color: "rgba(255,99,132,0.2)",
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      },
    };
  },
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    currency() {
      return (value) =>
        this.$options.filters.currency(value, "R$ ", 2, {
          spaceBetweenAmountAndSymbol: true,
          thousandsSeparator: ".",
          decimalSeparator: ",",
        });
    },
    getBtnToggleMinWidth() {
      return 100 / 4;
    },
    getChartReactiveStyles() {
      let styles = {
        position: "relative",
        margin: "auto",
        height: this.$store.state.drawer ? "50vh" : "40vh",
        width: this.$store.state.drawer ? "50vw" : "40vw",
      };

      if (this.$store.state.showFullscreenChart) {
        styles.minWidth = "90vw";
        styles.minHeight = "80vh";
      } else {
        styles.minWidth = "40vw";
        styles.minHeight = "50vh";
      }

      if (this.$vuetify.breakpoint.mobile) {
        styles.minWidth = "83vw";
        styles.minHeight = "29vh";

        if (this.$store.state.showFullscreenChart) {
          styles.minWidth = "95vw";
          styles.minHeight = "70vh";
        }
      }

      if (
        !this.$store.state.showFullscreenChart &&
        this.$vuetify.breakpoint.height < 500
      ) {
        styles.minWidth = "43vw";
        styles.minHeight = "29vh";
      }

      return styles;
    },
    getIndicator() {
      return ["amount", "sales", "average", "quant"][this.chartBtnToggle || 0];
    },
  },
  methods: {
    fillData() {
      const primaryColor = "rgb(18,138,20)";
      const backgroundPrimaryColor = "rgba(99,255,102,0.2)";

      const secondaryColor = "rgb(23,166,190)";
      const backgroundSecondaryColor = "rgba(23,166,190,0.2)";

      // linha abaixo deve ler a variavel para poder recarregar o front
      console.debug(this.sales.salesChartStatus);

      if (Object.keys(this.sales.salesMonthTotal).length === 0)
        return { labels: [], datasets: [] };

      const fillData = {
        labels: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        datasets: [
          {
            label: new Date().getFullYear(),
            pointBorderColor: primaryColor,
            pointHoverBorderColor: primaryColor,
            pointHoverBackgroundColor: primaryColor,
            pointBackgroundColor: primaryColor,
            backgroundColor: backgroundPrimaryColor,
            borderColor: primaryColor,
            borderWidth: 2,
            hoverBackgroundColor: backgroundPrimaryColor,
            hoverBorderColor: primaryColor,
            data: Object.keys(
              this.sales.salesMonthTotal[this.getIndicator]
            ).reduce((ac, it) => {
              ac.push(
                this.sales.salesMonthTotal[this.getIndicator][it].toFixed(2)
              );
              return ac;
            }, []),
          },
        ],
      };

      Object.keys(this.sales.salesLastYears).map((year) => {
        const dataset = {
          label: year,
          pointBorderColor: secondaryColor,
          pointHoverBorderColor: secondaryColor,
          pointHoverBackgroundColor: secondaryColor,
          pointBackgroundColor: secondaryColor,
          backgroundColor: backgroundSecondaryColor,
          borderColor: secondaryColor,
          borderWidth: 2,
          hoverBackgroundColor: backgroundSecondaryColor,
          hoverBorderColor: secondaryColor,
          data: Object.keys(
            this.sales.salesLastYears[year][this.getIndicator]
          ).reduce((ac, it) => {
            ac.push(
              this.sales.salesLastYears[year][this.getIndicator][it].toFixed(2)
            );
            return ac;
          }, []),
        };

        fillData.datasets.push(dataset);
      });

      return fillData;
    },
  },
};
</script>

<style lang="scss">
.home-chart {
  .btn-toggles {
    button {
      border-top: solid 1px #1a237e !important;
      border-left: solid 1px #1a237e !important;

      &:before {
        opacity: 0 !important;
      }
    }

    .v-btn-toggle .v-btn:not(.v-btn--active) {
      span {
        color: #1a237e;
      }
    }
  }
}
</style>
