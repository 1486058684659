<template>
  <v-card class="home-comparative">
    <!-- Titulo -->
    <v-card-title>
      <v-layout justify-space-between wrap align-center>
        <div class="body-2">COMPARATIVO DE VENDAS</div>
        <div title="filtrar">
          <v-btn icon @click="onToggleFilter">
            <v-icon v-if="!showFilter" color="light-blue lighten-1">mdi-filter</v-icon>
            <v-icon v-else color="grey">mdi-close</v-icon>
          </v-btn>
        </div>
      </v-layout>
    </v-card-title>
    <!-- Datas -->
    <v-card-text class="pa-2 px-4 pb-4">
      <!-- Alert Success -->
      <div v-if="showAlertSuccess" class="d-flex" style="width: 100%">
        <v-alert color="success lighten-5 green--text text--darken-1" class="pa-2 pr-3">
          <v-icon small color="success" class="mr-1">mdi-check-circle</v-icon>
          <span>resultados encontrados com sucesso!</span>
        </v-alert>
      </div>
      <v-row v-if="showFilter" class="flex-grow-0 align-end px-2 pb-6">
        <!-- Data Inicial Atual -->
        <v-col>
          <v-row class="grey lighten-4">
            <v-col cols="12" md="12" lg="12">
              <v-text-field v-model="sales.currentStartDate" type="date" label="Data Inicial Atual"/>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field v-model="sales.currentEndDate" type="date" label="Data Final Atual"/>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="py-0 pb-3 d-md-none">
        </v-col>
        <!-- Data Final Anterior -->
        <v-col>
          <v-row class="grey lighten-4">
            <v-col cols="12" md="12" lg="12">
              <v-text-field v-model="sales.previousStartDate" type="date" label="Data Inicial Anterior"/>
            </v-col>
            <v-col cols="12" md="12" lg="12">
              <v-text-field v-model="sales.previousEndDate" type="date" label="Data Final Anterior"/>
            </v-col>
          </v-row>
        </v-col>
        <!-- Buscar -->
        <v-col cols="12" class="d-flex flex-column justify-center justify-md-start pa-0 py-3">
          <div v-if="getStatusBuscar !== 1" class="d-flex flex-grow-1 flex-md-grow-0">
            <v-btn :block="$vuetify.breakpoint.smOnly" color="primary" @click="onSearch">
              Buscar
            </v-btn>
          </div>
          <div v-if="getStatusBuscar === 1" class="d-flex flex-column py-4" style="width: 100%">
            <span class="caption">buscando...</span>
            <v-progress-linear indeterminate />
          </div>
          <div v-if="getStatusBuscar === -1" class="d-flex pt-4" style="width: 100%">
            <v-alert color="red lighten-4 red--text text--lighten-1" class="pa-2">
              Algo de errado ocorreu, tente novamente.
            </v-alert>
          </div>
        </v-col>
      </v-row>
      <div v-if="Object.keys(sales.salesMonthTotal).length === 0" class="pa-3">
        {{Object.keys(sales.salesMonthTotal).length === 0 ? 'carregando...' : ''}}
      </div>
      <!-- Indicadores Comparativos -->
      <v-row no-gutters>
        <v-col cols="12" class="pb-3 px-0">
          <v-row class="container-comparatives overflow-y-auto" :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 310px)' : ''">
            <v-col class="px-2" cols="12" v-for="(item, i) in sales.comparatives" :key="i">
              <v-card :style="`border-left: solid 0.2rem ${item.borderColor}`">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="title text-md-body-1 pt-2 pb-0 indigo--text text--darken-3 d-flex flex-column align-center align-md-start">
                      {{item.title}}
                    </v-col>
                    <v-col cols="12" class="pb-3">
                      <v-row no-gutters>
                        <v-col cols="12" sm="6" class="d-flex justify-start flex-column">
                          <div class="d-flex flex-column">
                            <div class="caption">atual</div>
                            <span class="grey--text text--darken-4" style="font-size: 11px!important;">{{getDate(item.startDateA)}} - {{getDate(item.endDateA)}}</span>
                            <span v-if="![undefined, null].includes(item.amountA)" class="body-1 text-no-wrap black--text text--lighten-4 pr-5 py-2 font-weight-medium">
                              <span style="font-size: 12px">R$ </span>{{!item.amountA ? '0,00' : currency(item.amountA)}}
                            </span>
                            <span v-else class="body-1 text-no-wrap black--text text--lighten-4 pr-5 py-2 font-weight-medium">
                              {{ number(item.quantA) }}
                            </span>
                            <percent-indicator-compative v-if="item.amountA" :value-a="item.amountA" :value-b="item.amountB" />
                            <percent-indicator-compative v-else :value-a="item.quantA" :value-b="item.quantB" :is-int="true"/>
                          </div>
                        </v-col>
                        <v-col cols="12" class="py-3 d-flex d-sm-none">
                          <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" sm="6" class="d-flex justify-start flex-column">
                          <div class="d-flex flex-column">
                            <div class="caption">anterior</div>
                            <span class="grey--text text--darken-4" style="font-size: 11px!important;">{{getDate(item.startDateB)}} - {{getDate(item.endDateB)}}</span>
                            <span v-if="![undefined, null].includes(item.amountB)" class="body-1 text-no-wrap black--text text--lighten-4 pr-5 py-2 font-weight-medium">
                              <span style="font-size: 12px">R$ </span>{{!item.amountB ? '0,00' : currency(item.amountB)}}
                            </span>
                            <span v-else class="body-1 text-no-wrap black--text text--lighten-4 pr-5 py-2 font-weight-medium">
                              {{ number(item.quantB) }}
                            </span>
<!--                            <percent-indicator-compative v-if="item.amountB" :value-a="item.amountA" :value-b="item.amountB" />-->
<!--                            <percent-indicator-compative v-else :value-a="item.quantA" :value-b="item.quantB" />-->
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import mixins from "@/mixins"
import PercentIndicatorCompative from "@/components/pages/home/percent-indicator-compative";

export default {
  components: {PercentIndicatorCompative},
  mixins: [mixins],
  data: () => ({
    showFilter: false,
    showAlertSuccess: false,
  }),
  mounted() {
    const page = document.querySelector('.container-page');
    const comparatives = document.querySelector('.container-comparatives');

    const fn = function () {
      if(this.scrollTop === 0) {
        if (comparatives) comparatives.style.minHeight = 'calc(100vh - 310px)'
      } else if (this.offsetHeight === (this.scrollHeight - this.scrollTop)) {
        if (comparatives) comparatives.style.minHeight = 'calc(100vh - 73px)'
      }
    }

    page.removeEventListener('scroll', fn)
    page.addEventListener('scroll', fn)
  },
  computed: {
    sales() {
      return this.$store.state.millennium;
    },
    currency() {
      return value => !value ? 0.0 : this.$options.filters.currency(value, '', 2, { spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.', decimalSeparator: ',' })
    },
    number() {
      return value => !value ? 0 : this.$options.filters.currency(value, '', 0, { spaceBetweenAmountAndSymbol: false, thousandsSeparator: '.', decimalSeparator: ',' })
    },
    getStatusBuscar() {
      return this.$store.state.millennium.status
    }
  },
  methods: {
    getDate(date) {
      return date.split('-').reverse().join('/')
    },
    onToggleFilter() {
      this.showFilter = !this.showFilter;
      document.querySelector('.container-comparatives').scrollTo(0, 0);
    },
    onSearch() {
      this.$store.dispatch('millennium/getComparativeSales', {
        currentStartDate: this.sales.currentStartDate,
        currentEndDate: this.sales.currentEndDate,
        previousStartDate: this.sales.previousStartDate,
        previousEndDate: this.sales.previousEndDate
      })
          .then(() => {
            this.showFilter = false
            this.showAlertSuccess = true
            setTimeout(() => this.showAlertSuccess = false, 2000)
            this.$store.commit("millennium/setComparatives")
            document.querySelector('.container-comparatives').scrollTo(0, 0);
            if(!this.$vuetify.breakpoint.mobile) {
              document.querySelector('.container-page').scrollTo(0, 0);
            }
          })
          .catch((err) => this.catchErrors(err))
    }
  }
}
</script>

<style scoped>

</style>
